<template>
  <b-card>
    <!-- media -->
    <b-media no-body> 
      <b-media-aside> 
        <b-link>
          <b-img ref="previewEl" rounded :src="profile_user.foto" height="120" />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <b-card-text> {{profile_user.level_name}}</b-card-text>
        <b-card-text> {{profile_user.ket}}</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col md="12">
          <b-form-group>
            <label>File Dokumen</label>
            <b-form-file v-model="file" placeholder="Choose a file or drop it here..." ref="file" id="customFile"
              @input="uploadFile" drop-placeholder="Drop file here..." />
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group label="Username" label-for="account-username">
            <b-form-input v-model="profile_user.username" placeholder="Username" name="username" disabled />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Name" label-for="account-name">
            <b-form-input v-model="profile_user.nm_lengkap" name="name" placeholder="Name" />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="E-mail" label-for="account-e-mail">
            <b-form-input v-model="profile_user.email" name="email" placeholder="Email" />

          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="No Hp/ WA" label-for="account-company">
            <b-form-input v-model="profile_user.no_hp" name="company" placeholder="Company name" />
          </b-form-group>
        </b-col>

        <!-- alert -->
        <b-col cols="12" class="mt-75">
          <b-alert show variant="warning" class="mb-50">

          </b-alert>
        </b-col>
        <!--/ alert -->

        <b-col cols="12">
          <b-button @click="UpdatePrlofile()"   variant="primary"
            class="mt-2 mr-1">
            Save changes
          </b-button>

        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
  import Base from '@/config/Mixins_base';
  import axios from '@/config/Axios'



  import {
    BFormFile,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    t,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import {
    useInputImageRenderer
  } from '@core/comp-functions/forms/form-utils'
  import {
    ref
  } from '@vue/composition-api'

  export default {
    components: {
      BButton,
      BForm,
      BImg,
      BFormFile,
      BFormGroup,
      BFormInput,
      BRow,
      BCol,
      t,
      BCard,
      BCardText,
      BMedia,
      BMediaAside,
      BMediaBody,
      BLink,
    },
    directives: {
      Ripple,
    },
    mixins: [Base],

    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        file :{},
        photo_profil: "tes",
        profile_user: {},
        optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
        profileFile: null,
      }
    },
    mounted() {
      this.GetProfil();
    },
    methods: {
      resetForm() {},
      uploadFile() {
        const isAuthenticated = localStorage.getItem('token_auth');
        this.file = this.$refs.file.files[0];
        let formData = new FormData();
        formData.append('file', this.file);
        const self = this;
        axios.post('upload/profil', formData, {
            headers: {
              'Authorization': isAuthenticated,
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(function (response) {  
            self.file = {};
            self.update_foto(response.data.result.nm_file);

          })
          .catch(function (error) {
            console.log(error);
          });

      },
      async update_foto(foto) {
          const self = this;
          await axios({
            method: 'POST',
            url: '/api/aut/foto',
            data: {
              foto : foto
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
            })
            .then(function (response) {
            self.profile_user = response.data.result; 
      
            }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
            });
          },

      async GetProfil() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/aut/get_profil_user',
            data: {

            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.profile_user = response.data.result;
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },


      async UpdatePrlofile() {
        const self = this;
        await axios({
            method: 'POST',
            url: '/api/aut/update_profile',
            data: self.profile_user,
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.notification('warning', "200", response.data.message);
            self.profile_user = response.data.result;
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
    },
    setup() {
      const refInputEl = ref(null)
      const previewEl = ref(null)

      const {
        inputImageRenderer
      } = useInputImageRenderer(refInputEl, previewEl)

      return {
        refInputEl,
        previewEl,
        inputImageRenderer,
      }
    },
  }
</script>
